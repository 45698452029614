import React, { useState } from "react";
import axios from "axios";
import "./Modal.css";
import ReactGA from "react-ga";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

function Modal({ onClose }) {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
    });
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [location, setLocation] = useState(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    async function success(position) {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);

        fetch(`https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&appid=34baa9dffdf7fac130d457ce74910e22&units=metric`)
            .then(response => response.json())
            .then(data => {
                setLocation(data);
                console.log("location",data);
            })
            .catch(error => console.log(error));
    }

    async function error() {
        console.log("Unable to retrieve your location");
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        if (navigator.geolocation) {
            await navigator.geolocation.getCurrentPosition(success, error);
        } else {
            console.log("Geolocation not supported");
        }

        try {
            const response = await axios.post(`${apiUrl}/waitlists`, {
                ...(formData.name && {name: formData.name}),
                ...(formData.email && {email: formData.email}),
                ...(formData.phone && {phone_number: formData.phone}),
            });

            if (response.status === 201) {
                ReactGA.event({
                    category: 'Waitlist',
                    action: 'User added to waitlist'
                });
                alert("Thank you for joining the waitlist!");
                onClose();
            }
        } catch (error) {
            if (error.status === 409) {
                alert("User already added to waitlist");
                onClose();
            }else {
                alert("There was an error submitting the form. Please try again.");
            }
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="modal-backdrop">
            <div className="modal">
                <h2>Join the Waitlist</h2>
                <form onSubmit={handleSubmit}>
                    <label>
                        Name:
                        <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                        />
                    </label>
                    <label>
                        Email: <font color={"red"}>*</font>
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </label>
                    <label>
                        Phone Number:
                        <input
                            type="tel"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                        />
                    </label>
                    <button type="submit" disabled={isSubmitting}>
                        {isSubmitting ? "Submitting..." : "Submit"}
                    </button>
                    <button type="button" onClick={onClose} className="close-button">
                        Cancel
                    </button>
                </form>
            </div>
        </div>
    );
}

export default Modal;