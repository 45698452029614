// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.questionnaire {
    background-color: #2d4065;
    padding: 40px;
    max-width: 600px;
    margin: 0 auto;
    border-radius: 8px;
    text-align: center;
}

.questionnaire h2 {
    color: #333;
    margin-bottom: 20px;
}

.questionnaire form label {
    display: block;
    margin: 15px 0;
    text-align: left;
    color: #555;
}

.questionnaire form input,
.questionnaire form select,
.questionnaire form textarea {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.questionnaire form button {
    background-color: #e50914;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
}

.questionnaire form button:disabled {
    background-color: #aaa;
    cursor: not-allowed;
}`, "",{"version":3,"sources":["webpack://./src/Questionnaire.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,aAAa;IACb,gBAAgB;IAChB,cAAc;IACd,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,cAAc;IACd,gBAAgB;IAChB,WAAW;AACf;;AAEA;;;IAGI,WAAW;IACX,YAAY;IACZ,eAAe;IACf,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,WAAW;IACX,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,sBAAsB;IACtB,mBAAmB;AACvB","sourcesContent":[".questionnaire {\n    background-color: #2d4065;\n    padding: 40px;\n    max-width: 600px;\n    margin: 0 auto;\n    border-radius: 8px;\n    text-align: center;\n}\n\n.questionnaire h2 {\n    color: #333;\n    margin-bottom: 20px;\n}\n\n.questionnaire form label {\n    display: block;\n    margin: 15px 0;\n    text-align: left;\n    color: #555;\n}\n\n.questionnaire form input,\n.questionnaire form select,\n.questionnaire form textarea {\n    width: 100%;\n    padding: 8px;\n    margin-top: 5px;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n}\n\n.questionnaire form button {\n    background-color: #e50914;\n    color: #fff;\n    padding: 10px 20px;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n    margin-top: 20px;\n}\n\n.questionnaire form button:disabled {\n    background-color: #aaa;\n    cursor: not-allowed;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
