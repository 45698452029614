import React, { useState } from "react";
import "./Questionnaire.css";

function Questionnaire() {
    const [formData, setFormData] = useState({
        ageRange: "",
        favoriteGenre: "",
        streamingFrequency: "",
        improvements: "",
    });
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        try {
            const response = await fetch("https://api.example.com/questionnaire", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formData),
            });

            if (!response.ok) {
                throw new Error("Failed to submit the questionnaire");
            }

            alert("Thank you for your feedback!");
            setFormData({
                ageRange: "",
                favoriteGenre: "",
                streamingFrequency: "",
                improvements: "",
            });
        } catch (error) {
            alert("There was an error submitting the questionnaire. Please try again.");
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="questionnaire">
            <h2>User Questionnaire</h2>
            <form onSubmit={handleSubmit}>
                <label>
                    Age Range:
                    <select
                        name="ageRange"
                        value={formData.ageRange}
                        onChange={handleChange}
                        required
                    >
                        <option value="">Select an option</option>
                        <option value="18-24">18-24</option>
                        <option value="25-34">25-34</option>
                        <option value="35-44">35-44</option>
                        <option value="45+">45+</option>
                    </select>
                </label>

                <label>
                    Favorite Genre:
                    <input
                        type="text"
                        name="favoriteGenre"
                        value={formData.favoriteGenre}
                        onChange={handleChange}
                        required
                    />
                </label>

                <label>
                    How often do you stream?
                    <select
                        name="streamingFrequency"
                        value={formData.streamingFrequency}
                        onChange={handleChange}
                        required
                    >
                        <option value="">Select an option</option>
                        <option value="daily">Daily</option>
                        <option value="weekly">Weekly</option>
                        <option value="monthly">Monthly</option>
                        <option value="rarely">Rarely</option>
                    </select>
                </label>

                <label>
                    What would you like us to improve?
                    <textarea
                        name="improvements"
                        value={formData.improvements}
                        onChange={handleChange}
                    />
                </label>

                <button type="submit" disabled={isSubmitting}>
                    {isSubmitting ? "Submitting..." : "Submit"}
                </button>
            </form>
        </div>
    );
}

export default Questionnaire;