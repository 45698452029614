// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Modal Backdrop */
.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Modal Box */
.modal {
    background-color: #222;
    color: #fff;
    padding: 20px;
    width: 300px;
    border-radius: 8px;
    text-align: center;
}

.modal h2 {
    margin-bottom: 20px;
}

.modal form label {
    display: block;
    margin: 10px 0;
}

.modal form input {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border-radius: 4px;
    border: 1px solid #444;
    background-color: #333;
    color: #fff;
}

.modal form button {
    padding: 10px 20px;
    margin-top: 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.modal form button[type="submit"] {
    background-color: #e50914;
    color: white;
}

.modal form button.close-button {
    background-color: #555;
    color: white;
    margin-left: 10px;
}`, "",{"version":3,"sources":["webpack://./src/Modal.css"],"names":[],"mappings":"AAAA,mBAAmB;AACnB;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,8BAA8B;IAC9B,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;AACjB;;AAEA,cAAc;AACd;IACI,sBAAsB;IACtB,WAAW;IACX,aAAa;IACb,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,cAAc;AAClB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,eAAe;IACf,kBAAkB;IAClB,sBAAsB;IACtB,sBAAsB;IACtB,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,YAAY;IACZ,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI,sBAAsB;IACtB,YAAY;IACZ,iBAAiB;AACrB","sourcesContent":["/* Modal Backdrop */\n.modal-backdrop {\n    position: fixed;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    background: rgba(0, 0, 0, 0.7);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 1000;\n}\n\n/* Modal Box */\n.modal {\n    background-color: #222;\n    color: #fff;\n    padding: 20px;\n    width: 300px;\n    border-radius: 8px;\n    text-align: center;\n}\n\n.modal h2 {\n    margin-bottom: 20px;\n}\n\n.modal form label {\n    display: block;\n    margin: 10px 0;\n}\n\n.modal form input {\n    width: 100%;\n    padding: 8px;\n    margin-top: 5px;\n    border-radius: 4px;\n    border: 1px solid #444;\n    background-color: #333;\n    color: #fff;\n}\n\n.modal form button {\n    padding: 10px 20px;\n    margin-top: 15px;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n}\n\n.modal form button[type=\"submit\"] {\n    background-color: #e50914;\n    color: white;\n}\n\n.modal form button.close-button {\n    background-color: #555;\n    color: white;\n    margin-left: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
