import React, { useState } from "react";
import { Link } from "react-scroll";
import "./App.css";
import Modal from "./Modal";
import Questionnaire from "./Questionnaire";
import ReactGA from 'react-ga';

const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_NUMBER;
ReactGA.initialize(TRACKING_ID);

function App() {
    const [isModalOpen, setModalOpen] = useState(false);

    const handleWaitlistClick = () => {
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const currentYear = new Date().getFullYear();

    return (
        <div className="App">
            {/* Navigation Bar */}
            <nav className="navbar">
                <h1 className="logo">
                    {/*<img src={require("./img/SAVE_20241112_133818.jpg")} alt={ghost}/> */}
                    <Link to="home" smooth={true} duration={500}>
                        Optimalvid
                    </Link>
                </h1>
                <div className="nav-links">
                    <Link to="home" smooth={true} duration={500}>
                        Home
                    </Link>
                    <Link to="about" smooth={true} duration={500}>
                        About Us
                    </Link>
                    {/*<Link to="questionnaire" smooth={true} duration={500}>*/}
                    {/*    Questionnaire*/}
                    {/*</Link>*/}
                    <Link to="contact" smooth={true} duration={500}>
                        Contact
                    </Link>
                </div>
            </nav>

            {/* Hero Section */}
            <section id="home" className="hero-section">
                <div className="hero-content">
                    <h1>Optimalvid</h1>
                    <p>Stream Documentaries without Cellular data</p>
                    <button className="waitlist-button" onClick={handleWaitlistClick}>
                        Join Waitlist
                    </button>
                </div>
            </section>

            {/* About Section */}
            <section id="about" className="about-section">
                <h2>About Us</h2>
                <p>
                    A streaming platform where documentaries are streamed online without Cellular data (no wifi No offline downloads)
                </p>
            </section>

            {/* Questionnaire Section */}
            {/*<section id="questionnaire" className="questionnaire-section">*/}
            {/*    <Questionnaire />*/}
            {/*</section>*/}

            {/* Contact Section */}
            <section id="contact" className="contact-section">
                <h2>Contact Us</h2>
                <div className="social-icons">
                    <a href="https://x.com/optimalvid" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-twitter"></i>
                    </a>
                    <a href="https://facebook.com/optimalvid" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-facebook"></i>
                    </a>
                    <a href="mailto:Optimalvid@optimalvid.com">
                        <i className="fas fa-envelope"></i>
                    </a>
                    <a href="https://linkedin.com/company/optimalvid" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-linkedin"></i>
                    </a>
                    <a href="https://youtube.com/@optimalvid" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-youtube"></i>
                    </a>
                    <a href="https://instagram.com/optimalvid" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-instagram"></i>
                    </a>
                </div>
            </section>

            {/* Footer */}
            <footer className="footer">
                <p>© {currentYear} Optimalvid. All Rights Reserved.</p>
            </footer>

            {/* Modal Component */}
            {isModalOpen && <Modal onClose={handleModalClose}/>}
        </div>
    );
}

export default App;